// extracted by mini-css-extract-plugin
export var softBlueGradient = "AboutUs-module--soft-blue-gradient--1WOwE";
export var aboutUs = "AboutUs-module--aboutUs--261Dz";
export var aboutUsBackground = "AboutUs-module--aboutUsBackground--XD3Kx";
export var aboutUsAvatar = "AboutUs-module--aboutUsAvatar--2Y4U0";
export var quoteContainer = "AboutUs-module--quoteContainer--2ga-A";
export var quoteIcon = "AboutUs-module--quoteIcon--1Tqey";
export var quoteText = "AboutUs-module--quoteText--1nd0-";
export var spacer = "AboutUs-module--spacer--ITdIG";
export var listIcon = "AboutUs-module--listIcon--1IWV3";
export var texts = "AboutUs-module--texts--TWw_X";
export var aboutText = "AboutUs-module--aboutText--3g2Pn";