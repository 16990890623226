import React, { useState, useEffect, useRef } from 'react'
import '../styles/global.scss'

import { Header } from './Header'
import { MegaFooter } from './MegaFooter'
import { ToastAlert } from './ToastAlert'

import { Loading } from './Loading'
import { LoadingContext } from '../utils/LoadingContext'
import { useMediaQuery } from '../utils/hooks'

import scrollTo from 'gatsby-plugin-smoothscroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import clsx from 'clsx'
interface LayoutProps {
  isLargeFooter?: boolean
  location: typeof window.location
  mainFolder?: string
}

export const Layout: React.FC<LayoutProps> = ({
  location,
  mainFolder,
  children,
}) => {
  const isMobile = useMediaQuery(`780`)
  const [sticky, setSticky] = useState(false)
  const [pageEnd, setPageEnd] = useState(false)
  const [height, setHeight] = useState<number | undefined>(0)
  const ref = useRef<HTMLDivElement>(null)

  const onScroll = () => {
    if (height) setPageEnd(window.scrollY + window.innerHeight >= height)
    setSticky(window.scrollY > 1500)
  }

  useEffect(() => {
    window.addEventListener(`scroll`, onScroll)

    return () => {
      window.removeEventListener(`scroll`, onScroll)
      setHeight(ref?.current?.clientHeight)
    }
  }, [])

  useEffect(() => {
    if (ref.current)
      setPageEnd(
        window.scrollY + window.innerHeight >= ref.current.clientHeight
      )
  }, [ref.current])

  return (
    <main style={{ position: 'relative' }} ref={ref}>
      <ToastAlert />
      <Header
        mainFolder={mainFolder}
        type={isMobile ? `default` : `megaMenu`}
      />
      {children}
      <MegaFooter location={location.pathname} />
      <Loading context={LoadingContext} />
      <button
        className={clsx('backOnTop', {
          ['active']: sticky,
          ['onFooter']: pageEnd,
        })}
        onClick={() => scrollTo('body')}
      >
        <FontAwesomeIcon icon={faChevronUp} />
      </button>
    </main>
  )
}
