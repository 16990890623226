import React from 'react'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'

import * as styles from '../styles/components/Loading.module.scss'

import { LoadingContext } from '../utils/LoadingContext'
import logo from '../images/valore-logo.png'

export interface LoadingProps {
  context: typeof LoadingContext
}

export const Loading: React.FC<LoadingProps> = observer(({ context }) => {
  return (
    <div
      className={clsx(styles.loading, {
        [styles.active]: context.loading,
      })}
    >
      <div className={styles.content}>
        <img src={logo} alt="Valore Melhoria" />
      </div>
    </div>
  )
})
