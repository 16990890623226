import React from 'react'
import clsx from 'clsx'

import * as styles from '../styles/components/Statistics.module.scss'

interface StatisticsProps {
  largeMargin?: boolean
}

export const Statistics: React.FC<StatisticsProps> = ({ largeMargin }) => {
  return (
    <section
      id="numeros"
      className={clsx([
        styles.statistics,
        { [styles.largeMargin]: largeMargin },
        'my-4',
      ])}
    >
      <div className="pt-3 pb-5">
        <div className="container">
          <div className={clsx([styles.subtitle, 'mb-2 mb-lg-5'])}>
            <p>
              Esses são alguns números da Valore Brasil em gerenciamento de
              projetos
            </p>
          </div>
          <div className="row justify-content-center">
            <div className={clsx([`col-lg-3`, styles.statisticsItem])}>
              <b>
                <small>+</small> 12
              </b>
              <span>Anos de mercado</span>
            </div>

            <div className={clsx([`col-lg-3`, styles.statisticsItem])}>
              <b>
                <small>+</small> 300
              </b>
              <span>Projetos no portfólio</span>
            </div>

            <div className={clsx([`col-lg-3`, styles.statisticsItem])}>
              <b>
                <small>+</small> 3000
              </b>
              <span>Processos Melhorados</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
