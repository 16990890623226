import React from 'react'
import clsx from 'clsx'

import * as styles from '../styles/components/AboutUs.module.scss'
import favicon from '../images/favicon.png'

export const AboutUs: React.FC = () => {
  return (
    <section
      id="quem-somos"
      className={clsx([`my-4`, 'container', 'containerLarge'])}
    >
      <div className="row">
        <div className={styles.texts}>
          <div className={styles.aboutText}>
            <img src={favicon} alt="favicon" />
            <h1>
              Melhorar resultados, <strong>JUNTOS!</strong>
            </h1>
            <p>
              Com atuação em todo o território nacional, nosso time possui
              formação multidisciplinar, com perfis analíticos e focados em
              melhorias. Além disso, nossos profissionais possuem as principais
              certificações do mercado em Gestão por Processos (CBPP e OCEB),
              Gestão de Projetos (PMI), Gestão de Serviços em T.I (ITIL e
              COBIT), Auditores Líderes (IRCA), Inteligência Artificial, dentre
              outras. Soluções personalizadas para desafios em comum.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
